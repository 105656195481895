import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const WellspringNutritionLandingPage = () => (
  <PhysicianLandingPage
    physician="Anabelle"
    institution="Wellspring Nutrition"
    referralCode="WELLSPRING"
  />
)

export default WellspringNutritionLandingPage
